import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import { Observable, Observer } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class AlertService {

  constructor() { }

  successAlert(message : any)
  {
    Swal.fire({
      title: 'Success!',
      text: message,
      icon: 'success',
      confirmButtonText: 'Okay',
      showConfirmButton : false,
      confirmButtonColor: '#1777D0',
      timer: 2000,
      showClass: {
        popup: 'animate__animated animate__fadeInDown'
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp'
      }
    })
  }
  failureAlert(message : any)
  {
    Swal.fire({
      title: 'Error',
      text: message,
      icon: 'error',
      confirmButtonText: 'Okay',
      confirmButtonColor: '#1777D0',
      showClass: {
        popup: 'animate__animated animate__fadeInDown'
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp'
      }
    })
  }

  confirmAlert(message: any): Observable<string> {
    return new Observable<string>((observer: Observer<string>) => {
      Swal.fire({
        title: 'Are You Sure?',
        text: message,
        icon: 'warning',
        confirmButtonText: 'Yes',
        denyButtonText: 'Cancel',
        showDenyButton: true,
        confirmButtonColor: '#1777D0',
        denyButtonColor: '#F95C5C',
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          observer.next('confirm');
          observer.complete();
        } else if (result.isDenied) {
          observer.next('cancel');
          observer.complete();
        } else {
          observer.next('dismiss');
          observer.complete();
        }
      });
    });
  }

  enquiryAddConfirmAlert(message: any): Observable<string> {
    return new Observable<string>((observer: Observer<string>) => {
      Swal.fire({
        title: message,
        text: 'Would you like to go to the enquiry detail ?',
        icon: 'success',
        confirmButtonText: 'Go to detail',
        denyButtonText: 'Close',
        showDenyButton: true,
        confirmButtonColor: '#1777D0',
        denyButtonColor: '#F95C5C',
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          observer.next('confirm');
          observer.complete();
        } else if (result.isDenied) {
          observer.next('cancel');
          observer.complete();
        } else {
          observer.next('dismiss');
          observer.complete();
        }
      });
    });
  }
}
