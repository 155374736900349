import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ApiCallMethodsService {

  constructor(private http: HttpClient) { }

  get(url:any){
    let apiRoute = environment.apiUrl + url;
    return new Observable((observer) => {
      this.http.get(apiRoute).subscribe(
        (data) => {
          observer.next(data);
          observer.complete();
        },
        (err) => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  getBlob(url: any) {
    let apiRoute = environment.apiUrl + url;
    return this.http.get(apiRoute, { responseType: 'blob' });
  }

  post(url:any, data:any) {
    let apiRoute = environment.apiUrl + url;
    return new Observable((observer) => {
      this.http.post(apiRoute, data).subscribe(
        (data) => {
          observer.next(data);
          observer.complete();
        },
        (err) => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  put(url:any, data:any) {
    let apiRoute = environment.apiUrl + url;
    return new Observable((observer) => {
      this.http.put(apiRoute, data).subscribe(
        (data) => {
          observer.next(data);
          observer.complete();
        },
        (err) => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  delete(url:any) {
    let apiRoute = environment.apiUrl + url;
    return new Observable((observer) => {
      this.http.delete(apiRoute).subscribe(
        (data) => {
          observer.next(data);
          observer.complete();
        },
        (err) => {
          observer.error(err);
          observer.complete();
        }
      );
    });
   }
}
