import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { apiRoutes } from 'src/app/constants/apiRoutes';
import { AlertService } from 'src/app/shared/services/alert-service/alert.service';
import { ApiCallMethodsService } from 'src/app/shared/services/api-call-methods-service/api-call-methods.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerDetailsService {
  SharingData = new Subject();
  customerId: any = null;
  // ENQUIRY LISTING
  enquiryListPage: any = 1;
  enquiryListRows: any = 10;
  enquiryList: any = null;
  // STORAGE ENQUIRY LISTING
  enquiryStorageListPage: any = 1;
  enquiryStorageListRows: any = 10;
  enquiryStorageList: any = null;
  // ORDER LISTING
  orderListPage: any = 1;
  orderListRows: any = 10;
  orderList: any = null;
  // STORAGE ORDER LISTING
  orderStorageListPage: any = 1;
  orderStorageListRows: any = 10;
  orderStorageList: any = null;
  // PAYMENT LISTING
  paymentsListPage: any = 1;
  paymentsListRows: any = 10;
  paymentsList: any = null;
  // OCUSTOMER DETAILS
  customerDetailsData: any = null;
  constructor(private apiCallMethodsService: ApiCallMethodsService,
    private router: Router,
    private alertService: AlertService) { }
  getCustomerId() {
    const idRegex = /\/customers-details\/([^/]+)\//;
    const match = this.router.url.match(idRegex);
    if (match && match.length > 1) {
      this.customerId = match[1];

    }
  }
  getEnquiresList(pageNumber, rowsPerPage) {

    this.getCustomerId();

    let apiUrl = `${apiRoutes.customer}/${this.customerId}/enquiry-list?pageNumber=${pageNumber}&pageSize=${rowsPerPage}`;

    this.apiCallMethodsService.get(apiUrl).subscribe((response: any) => {

      let listingData;

      this.enquiryList = response;
      listingData = {
        enquires: this.enquiryList,
      }

      this.SharingData.next(listingData);

    });

  }

  // STORAGE ENQ LIST
  getStorageEnquiresList(pageNumber, rowsPerPage) {

    this.getCustomerId();

    let apiUrl = `${apiRoutes.customer}/${this.customerId}/storage/enquiries?pageNumber=${pageNumber}&pageSize=${rowsPerPage}`;

    this.apiCallMethodsService.get(apiUrl).subscribe((response: any) => {

      let listingData;

      this.enquiryStorageList = response;
      listingData = {
        enquires: this.enquiryStorageList,
      }

      this.SharingData.next(listingData);

    });

  }

  // ORDER LIST
  getOrdersList(pageNumber, rowsPerPage) {

    this.getCustomerId();

    let apiUrl = `${apiRoutes.customer}/${this.customerId}/order-list?pageNumber=${pageNumber}&pageSize=${rowsPerPage}`;
    this.apiCallMethodsService.get(apiUrl).subscribe((response: any) => {

      let listingData;

      this.orderList = response;
      listingData = {
        orders: this.orderList,
      }

      this.SharingData.next(listingData);

    });

  }

  // ORDER LIST
  getStorageOrdersList(pageNumber, rowsPerPage) {

    this.getCustomerId();

    let apiUrl = `${apiRoutes.customer}/${this.customerId}/storage/orders?pageNumber=${pageNumber}&pageSize=${rowsPerPage}`;
    this.apiCallMethodsService.get(apiUrl).subscribe((response: any) => {

      let listingData;

      this.orderStorageList = response;
      listingData = {
        orders: this.orderStorageList,
      }

      this.SharingData.next(listingData);

    });

  }

  // PAYMENT LIST
  getPaymentsList(pageNumber, rowsPerPage) {
    
    this.getCustomerId();
    
    let apiUrl = `${apiRoutes.customer}/${this.customerId}/payment-list?pageNumber=${pageNumber}&pageSize=${rowsPerPage}`;
    this.apiCallMethodsService.get(apiUrl).subscribe((response: any) => {

      let listingData;

      this.paymentsList = response;
      listingData = {
        payments: this.paymentsList,
      }

      this.SharingData.next(listingData);

    });

  }

  // CUSTOMER DETAILS
  getCustomerDetails(id = null) {

    if (!id) {
      this.getCustomerId();
    }

    let apiUrl = `${apiRoutes.customer}/${id ? id : this.customerId}/details`;
    this.apiCallMethodsService.get(apiUrl).subscribe((response: any) => {
      let customerDetails;
      this.customerDetailsData = response;
      customerDetails = {
        details: this.customerDetailsData,
      };
      let customerId;
      customerId = this.customerId;
      this.SharingData.next(customerDetails);
      this.SharingData.next(customerId);
    })
  }

  //EDIT CUSTOMER
  customerEdit(payload,id=null) {
    payload.organisationId = this.customerDetailsData?.data?.organisationId;

    let url = `${apiRoutes.customer}/${id ? id : this.customerId}/modify`;
    
    this.apiCallMethodsService.put(url, payload).subscribe((response: any) => {
      if (response.succeeded == true) {
        this.alertService.successAlert(response.message[0]);
        setTimeout(() => {
          this.getCustomerDetails(id ? id : null);
        }, 2000)
      }
      else{
        this.alertService.failureAlert(response.message[0]);
      }
    }, (error: any) => {
      this.alertService.failureAlert(error.error.message[0]);
    });

  }

  
  //  DOCUMENT
  addDocument(payload, params) {

    if (payload && params) {

      let apiUrl = `${apiRoutes.customer}/${this.customerDetailsData?.data?.id}/upload-documents?folderName=${params.folderName}&documentId=${params.documentId ? params.documentId : ''}&documentType=${params.documentType}&orderId=`

      this.apiCallMethodsService.post(apiUrl, payload).subscribe((response: any) => {
        if (response.succeeded == true) {
          this.alertService.successAlert(response.message[0]);
          setTimeout(() => {
            this.getCustomerDetails();
          }, 2000)
        }
        else{
          this.alertService.failureAlert(response.message[0]);
        }
      }, (error: any) => {
        this.alertService.failureAlert(error.error.message[0]);
      });

    }

  }
}
