<div class="d-flex align-items-center">

    <div class="static-options" [ngClass]="{'table-filter': type == 'dashboard-table'}">

        <button class="d-flex w-100 justify-content-between align-items-center" #matMenuBtn [matMenuTriggerFor]="menu" matRipple>

            <svg *ngIf="type !== 'dashboard-table'" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19 4H5C3.89543 4 3 4.89543 3 6V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V6C21 4.89543 20.1046 4 19 4Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M16 2V6" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M8 2V6" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M3 10H21" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
             </svg> 

            <h5 *ngIf="selectedFilter?.type !== 'Custom Range'" class="my-0 mx-75">{{selectedFilter?.type}}</h5>
            <h5 *ngIf="selectedFilter?.type == 'Custom Range'" class="my-0 mx-75">{{selectedFilter?.start + ' to ' + selectedFilter?.end}}</h5>
            
            <mat-icon>keyboard_arrow_down</mat-icon>
            
          </button>

        <mat-menu #menu="matMenu" class="date-filter-menu" [class]="type == 'dashboard-table' ? 'table-filter-menu' : ''">
            <p mat-menu-item (click)="selectFilter(1)">Last 1 month</p>
            <p mat-menu-item (click)="selectFilter(2)">Last 3 months</p>
            <p mat-menu-item (click)="selectFilter(3)">Last 6 months</p>
            <p mat-menu-item (click)="$event.stopPropagation(); picker.open()">Custom</p>
        </mat-menu>

        <div class="hidden-datepicker">
            <mat-form-field>
                <mat-date-range-input [rangePicker]="picker">
                    <input matStartDate #startDateInput placeholder="Start date" [(ngModel)]="startDate">
                    <input matEndDate #endDateInput placeholder="End date" [(ngModel)]="endDate">
                </mat-date-range-input>
                <mat-date-range-picker #picker>
                    <mat-date-range-picker-actions>
                        <button class="secondary-btn date mx-2" mat-button matDateRangePickerCancel>Cancel</button>
                        <button class="primary-btn date" mat-raised-button color="primary" (click)="selectFilter('Custom',{start: startDate, end: endDate}); closeMenu()"  matDateRangePickerApply>Apply</button>
                    </mat-date-range-picker-actions>
                </mat-date-range-picker>
            </mat-form-field>
        </div>

    </div>

</div>