import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'divide',
})
export class ConvertMoneyPipe implements PipeTransform {
  transform(value: any, divisor: number): any {
    const subTotal = parseFloat(value);
    if (isNaN(subTotal)) {
      return '0.00';
    }
    return (subTotal / divisor).toFixed(2);
  }
}

