<div class="d-flex">

    <div class="country-picker">
        <button class="country-code-picker cursor-pointer" mat-button [matMenuTriggerFor]="menu">

            <div class="d-flex align-items-center">

                <span class="country-flag">
                    <img [src]="selectedCountry?.flagUrl" id="shown-flag" alt="">
                </span>
                <span class="d-inline-block ms-1">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4 6L8 10L12 6" stroke="#7C7C7C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                                        </span>
                <span class="d-inline-block ms-1 me-1">
                    <p class="mb-0">+{{selectedCountry?.code}}</p>
                </span>

                <img src="../../../../../assets/images/divider.png" class="divider me-1" alt=""> 
            </div>

        </button>
        
        <mat-menu #menu="matMenu">

          <div class="country-flag mx-0" mat-menu-item *ngFor="let country of countriesList" (click)="selectCountry(country)">
              <img [src]="country?.flagUrl" class="country-flag menu-flag" alt="">
              <p class="mb-0 d-inline-block menu-name">{{country?.country + ' +' + country?.code}}</p>
          </div>

        </mat-menu>
  
    </div>

</div>