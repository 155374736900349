import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { apiRoutes } from 'src/app/constants/apiRoutes';
import { AlertService } from 'src/app/shared/services/alert-service/alert.service';
import { ApiCallMethodsService } from 'src/app/shared/services/api-call-methods-service/api-call-methods.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Injectable({
  providedIn: 'root'
})
export class MainService {

  SharingData = new Subject();

  // CUSTOMER LISTING
  customerListPage: any = 1;
  customerListRows: any = 10;
  customerList: any = null;
  // ENQUIRY LISTING
  enquiryListPage: any = 1;
  enquiryListRows: any = 10;
  enquiryList: any = null;
  statusValue: any = [];

  // STORAGE ENQUIRY LISTING
  storageEnquiryListPage: any = 1;
  storageEnquiryListRows: any = 10;
  storageEnquiryList: any = null;
  storageStatusValue: any = [];

  // ORDER LISTING
  orderListPage: any = 1;
  orderListRows: any = 10;
  orderList: any = null;
  orderFilters: any = null;
  orderStatusValue: any = [];

  // STORAGE ORDER LISTING
  storageOrderListPage: any = 1;
  storageOrderListRows: any = 10;
  storageOrderList: any = null;
  storageOrderFilters: any = null;
  storageOrderStatusValue: any = [];

  // VEHICLE LISTING
  vehicleListPage: any = 1;
  vehicleListRows: any = 10;
  vehicleList: any = null;
  allVehiclesList: any = null;
  // DRIVER LISTING
  driverListPage: any = 1;
  driverListRows: any = 10;
  driverList: any = null;
  allDriversList: any = null;

  // PRICING INVENTORY LISTING
  pricingListPage: any = 1;
  pricingListRows: any = 10;
  pricingList: any = null;
  allPricingInventoryList: any = null;

  // STORAGE INVENTORY LISTING
  storageListPage: any = 1;
  storageListRows: any = 10;
  storageList: any = null;
  allStorageInventoryList: any = null;
  orderListPageNumber: any;
  orderListRowperPage: any;
  orderStoragerowsperpage: any;
  orderStoragePageNumber: any;
  supplierListPage: any = 1;
  supplierListRows: any = 10;
  supplierList: any = null;
  supplierDetails: any;
  constructor(private apiCallMethodsService: ApiCallMethodsService, private loaderService: NgxUiLoaderService, private router: Router, private alertService: AlertService, private dialog: MatDialog) { }
  //CUSTOMER LIST

  getCustomersList(pageNumber, rowsPerPage, filterOptions = null) {

    let name;
    let email;
    let phoneNumber;
    let orgName;
// new
let searchValue;
let searchBy;
console.log(filterOptions);

if (filterOptions) {
  if (filterOptions != null) {
    searchValue = filterOptions?.searchValue;
    searchBy = filterOptions?.searchBy;
  }
}


    if (filterOptions) {
      if (filterOptions.email != null) {
        email = filterOptions.email;
      }
      else if (filterOptions.phoneNumber != null) {
        phoneNumber = filterOptions.phoneNumber;
      }
      else if (filterOptions.name != null) {
        name = filterOptions.name;
      }
      else if (filterOptions.orgName != null) {
        orgName = filterOptions.orgName;
      }
      else {
        name = filterOptions.name;
      }
    }

    let apiUrl = `${apiRoutes.customer + '/list'}?searchValue${searchValue ? '=' + searchValue : ''}&searchBy=${searchBy ? searchBy : ''}&pageNumber=${pageNumber}&pageSize=${rowsPerPage}`;
    console.log(filterOptions);

    // let apiUrl = `${apiRoutes.customer + '/list'}?name${name ? '=' + name : ''}&email${email ? '=' + email : ''}&orgName${orgName ? '=' + orgName : ''}&phoneNumber${phoneNumber ? '=' + phoneNumber : ''}&pageNumber=${pageNumber}&pageSize=${rowsPerPage}`;
    this.apiCallMethodsService.get(apiUrl).subscribe((response: any) => {

      let listingData;

      this.customerList = response;
      listingData = {
        customers: this.customerList,
      }

      this.SharingData.next(listingData);

    },
      (error: any) => {

        let listingData = {
          customers: [],
        }
        this.SharingData.next(listingData);

      });


  }
  addNewCustomer(payload) {
    if (payload) {
      let apiUrl = `${apiRoutes.customer}/create`;
      this.apiCallMethodsService.post(apiUrl, payload).subscribe((response: any) => {
        if (response.succeeded == true) {
          console.log(true);
          this.alertService.successAlert(response.message[0]);
          setTimeout(() => {
            this.dialog.closeAll();
            this.getCustomersList(this.customerListPage, this.customerListRows)
          })
        }
        else {
          console.log(true);
          this.alertService.failureAlert(response.message[0]);
        }
      }, (error: any) => {
        console.log(error);
        this.alertService.failureAlert(error.error.message[0]);
      })
    }
  }

  //ENQUIRY LIST
  getEnquiryFilterByStatus(statusValues) {
    if (statusValues) {
      this.statusValue = statusValues;
    }

  }
  getEnquiresList(pageNumber, rowsPerPage, filterOptions = null) {

    let searchValue;
    let searchBy;

    if (filterOptions) {
      if (filterOptions != null) {
        searchValue = filterOptions?.searchValue;
        searchBy = filterOptions?.searchBy;
      }
    }

    let apiUrl = `${apiRoutes.enquiry + '/list'}?searchValue${searchValue ? '=' + searchValue : ''}&searchBy=${searchBy ? searchBy : ''}&pageNumber=${pageNumber}&pageSize=${rowsPerPage}&status=${this.statusValue || ''}`;

    this.apiCallMethodsService.get(apiUrl).subscribe((response: any) => {

      let listingData;

      this.enquiryList = response;
      listingData = {
        enquires: this.enquiryList,
      }

      this.SharingData.next(listingData);

    },
      (error: any) => {

        this.enquiryList = error.error;

        let listingData = {
          enquires: [],
        }
        this.SharingData.next(listingData);

      });

  }

  // STORAGE ENQUIRY LIST 
  getStorageFilterByStatus(storageStatusValue) {
    if (storageStatusValue) {
      this.storageStatusValue = storageStatusValue;
    }
  }

  getStorageEnquiresList(pageNumber, rowsPerPage, filterOptions = null) {

    let searchValue;
    let searchBy;

    if (filterOptions) {
      if (filterOptions != null) {
        searchValue = filterOptions?.searchValue;
        searchBy = filterOptions?.searchBy;
      }
    }

    let apiUrl = `${apiRoutes.storageInventory}/enquiry/list?searchValue${searchValue ? '=' + searchValue : ''}&searchBy=${searchBy ? searchBy : ''}&pageNumber=${pageNumber}&pageSize=${rowsPerPage}&status=${this.storageStatusValue || ''}`;

    this.apiCallMethodsService.get(apiUrl).subscribe((response: any) => {

      let listingData;

      this.storageEnquiryList = response;
      listingData = {
        enquires: this.storageEnquiryList,
      }

      this.SharingData.next(listingData);

    },
      (error: any) => {

        this.storageEnquiryList = error.error;

        let listingData = {
          enquires: [],
        }
        this.SharingData.next(listingData);

      });

  }

  //ORDER LIST

  getOrdersFilterByStatus(orderStatusValues) {
    if (orderStatusValues) {
      this.orderStatusValue = orderStatusValues;
    }
  }

  // getOrdersList(pageNumber, rowsPerPage, filterOptions = null) {
  //   console.log("filterOptions", filterOptions)

  //   let searchValue;
  //   let searchBy;

  //   if (filterOptions) {
  //     if (filterOptions != null) {
  //       searchValue = filterOptions?.searchValue;
  //       searchBy = filterOptions?.searchBy;
  //     }
  //   }

  //   let apiUrl = `${apiRoutes.order + '/list'}?searchValue${searchValue ? '=' + searchValue : ''}&searchBy=${searchBy ? searchBy : ''}&pageNumber=${pageNumber}&pageSize=${rowsPerPage}&status=${this.orderStatusValue || ''}`;

  //   this.apiCallMethodsService.get(apiUrl).subscribe((response: any) => {

  //     let listingData;

  //     this.orderList = response;
  //     listingData = {
  //       orders: this.orderList,
  //     }

  //     this.SharingData.next(listingData);

  //   },
  //     (error: any) => {

  //       this.enquiryList = error.error;

  //       let listingData = {
  //         enquires: [],
  //       }
  //       this.SharingData.next(listingData);

  //     });
  // }

  getOrdersList(pageNumber, rowsPerPage, filterOptions = null) {
    // console.log(filterOptions,'filterOptions');

    let val;
    this.orderListPageNumber = pageNumber;
    this.orderListRowperPage = rowsPerPage;
    if (filterOptions != null) {
      val = filterOptions.searchValue;
      this.orderFilters = filterOptions;
    }
    else {
      this.orderFilters = filterOptions;
    }

    if (this.orderFilters['searchBy'] == "orderNumber") {
      var apiUrl = `${apiRoutes.order + '/list'}?orderNumber${val ? '=' + val : ''}&pageNumber=${pageNumber}&pageSize=${rowsPerPage}&status=${this.orderStatusValue || ''}&searchBy=${filterOptions.searchBy ? filterOptions.searchBy : ''}`;
      //  console.log(apiUrl,'orderNumber')

    }
    else if (this.orderFilters['searchBy'] == "orgname") {
      var apiUrl = `${apiRoutes.order + '/list'}?orgname${val ? '=' + val : ''}&pageNumber=${pageNumber}&pageSize=${rowsPerPage}&status=${this.orderStatusValue || ''}&searchBy=${filterOptions.searchBy ? filterOptions.searchBy : ''}`;
      //  console.log(apiUrl,'orgname')

    }
    else {
      var apiUrl = `${apiRoutes.order + '/list'}?&pageNumber=${pageNumber}&pageSize=${rowsPerPage}&status=${this.orderStatusValue || ''}`;

    }

    this.apiCallMethodsService.get(apiUrl).subscribe((response: any) => {

      let listingData;

      this.orderList = response;
      listingData = {
        orders: this.orderList,
      }

      this.SharingData.next(listingData);

    },
      (error: any) => {

        let listingData = {
          orders: [],
        }
        this.SharingData.next(listingData);

      });
  }


  //STORAGE ORDER LIST
  getOrderStorageFilterByStatus(selectedOrdersStorageStatuses) {
    if (selectedOrdersStorageStatuses) {
      this.storageOrderStatusValue = selectedOrdersStorageStatuses;
    }
  }

  // getStorageOrdersList(pageNumber, rowsPerPage, filterOptions = null) {

  //   let searchValue;
  //   let searchBy;

  //   if (filterOptions) {
  //     if (filterOptions != null) {
  //       searchValue = filterOptions?.searchValue;
  //       searchBy = filterOptions?.searchBy;
  //     }
  //   }

  //   let apiUrl = `${apiRoutes.storageInventory}/orders?orderssearchValue${searchValue ? '=' + searchValue : ''}&searchBy=${searchBy ? searchBy : ''}&pageNumber=${pageNumber}&pageSize=${rowsPerPage}&status=${this.storageOrderStatusValue || ''}`;

  //   this.apiCallMethodsService.get(apiUrl).subscribe((response: any) => {

  //     let listingData;

  //     this.storageOrderList = response;
  //     listingData = {
  //       orders: this.storageOrderList,
  //     }

  //     this.SharingData.next(listingData);

  //   },
  //     (error: any) => {

  //       this.storageOrderList = error.error;

  //       let listingData = {
  //         orders: [],
  //       }
  //       this.SharingData.next(listingData);

  //     });

  // }

  getStorageOrdersList(pageNumber, rowsPerPage, filterOptions = null) {
    let val;
    this.orderStoragePageNumber = pageNumber;
    this.orderStoragerowsperpage = rowsPerPage;
    if (filterOptions) {
      if (filterOptions != null) {
        val = filterOptions.searchValue;
        this.storageOrderFilters = filterOptions;
      }
      else {
        this.storageOrderFilters = filterOptions;
      }
    }
    if (this.storageOrderFilters['searchBy'] == "orderNumber") {
      var apiUrl = `${apiRoutes.storageInventory}/orders?orderNumber${val ? '=' + val : ''}&pageNumber=${pageNumber}&pageSize=${rowsPerPage}&status=${this.storageOrderStatusValue || ''}&searchBy=${filterOptions.searchBy ? filterOptions.searchBy : ''}`;

      // console.log(apiUrl,'orderNumber')

    }
    else if (this.storageOrderFilters['searchBy'] == "orgname") {
      var apiUrl = `${apiRoutes.storageInventory}/orders?orgname${val ? '=' + val : ''}&pageNumber=${pageNumber}&pageSize=${rowsPerPage}&status=${this.storageOrderStatusValue || ''}&searchBy=${filterOptions.searchBy ? filterOptions.searchBy : ''}`;

      // console.log(apiUrl,'orgname')

    }
    else {
      var apiUrl = `${apiRoutes.storageInventory}/orders?orderNumber${val ? '=' + val : ''}&pageNumber=${pageNumber}&pageSize=${rowsPerPage}&status=${this.storageOrderStatusValue || ''}`;
      //  console.log(apiUrl,'reset')
    }
    // console.log(apiRoutes,'******')
    this.apiCallMethodsService.get(apiUrl).subscribe((response: any) => {

      let listingData;

      this.storageOrderList = response;
      listingData = {
        orders: this.storageOrderList,
      }

      this.SharingData.next(listingData);

    },
      (error: any) => {

        let listingData = {
          orders: [],
        }
        this.SharingData.next(listingData);

      });

  }

  downloadOrderCsv() {

    // console.log( this.orderListPageNumber,this.orderListRowperPage,this.orderFilters,this.orderStatusValue)
    // let apiUrl = `${apiRoutes.order + '/export'}?orderNumber${this.orderFilters?.orderNumber ? '=' + this.orderFilters?.orderNumber : ''}`;
    if (this.orderFilters['searchBy'] == "orgname") {
      var apiUrl = `${apiRoutes.order + '/export'}?orgname${this.orderFilters?.searchValue ? '=' + this.orderFilters?.searchValue : ''}&status=${this.orderStatusValue || ''}&searchBy=${this.orderFilters.searchBy ? this.orderFilters.searchBy : ''}`;
    }
    else {
      var apiUrl = `${apiRoutes.order + '/export'}?orderNumber${this.orderFilters?.searchValue ? '=' + this.orderFilters?.searchValue : ''}&status=${this.orderStatusValue || ''}&searchBy=${this.orderFilters.searchBy ? this.orderFilters.searchBy : ''}`;
    }
    // console.log(apiUrl,'*******-------------');


    this.apiCallMethodsService.getBlob(apiUrl).subscribe((response: Blob) => {

      const blob = new Blob([response], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'order_list.csv';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);

    }, (error: any) => {
      console.log(error.error.message[0]);
    })
  }
  downloadOrderStorageCsv() {
    if (this.storageOrderFilters['searchBy'] == "orgname") {
      var apiUrl = `${apiRoutes.storageInventory + '/order/exports'}?orgname${this.storageOrderFilters?.searchValue ? '=' + this.storageOrderFilters?.searchValue : ''}&status=${this.storageOrderStatusValue || ''}&searchBy=${this.storageOrderFilters.searchBy ? this.storageOrderFilters.searchBy : ''}`;
    }
    else {
      var apiUrl = `${apiRoutes.storageInventory + '/order/exports'}?orderNumber${this.storageOrderFilters?.searchValue ? '=' + this.storageOrderFilters?.searchValue : ''}&status=${this.storageOrderStatusValue || ''}&searchBy=${this.storageOrderFilters.searchBy ? this.storageOrderFilters.searchBy : ''}`;
    }
    console.log(apiUrl, '*******-------------');
    this.apiCallMethodsService.get(apiUrl).subscribe((response: any) => {
      // Extract the file URL from the JSON response
      const fileUrl = response.data.awsLink;
      // Create an anchor element and set the href to the file URL
      const a = document.createElement('a');
      a.href = fileUrl;  // Use the file URL directly
      a.download = '';   // Let the browser determine the file name from the URL
      document.body.appendChild(a);
      a.click();         // Trigger the download
      document.body.removeChild(a);  // Clean up the DOM

    }, (error: any) => {
      console.log(error.error.message[0]);
    });
  }

  //VEHICLE LIST
  getVehiclesList(pageNumber, rowsPerPage, from, filterOptions = null) {
    let vehicleNo;

    if (filterOptions) {
      if (filterOptions != null) {
        vehicleNo = filterOptions.vehicleNo;
        console.log(filterOptions);
      }
    }
    let apiUrl = `${apiRoutes.vehicle + '/list'}?pageNumber=${pageNumber}&pageSize=${rowsPerPage}&vehicleNo${vehicleNo ? '=' + vehicleNo : ''}`;

    this.apiCallMethodsService.get(apiUrl).subscribe((response: any) => {

      let listingData;

      if (from == 'list') {
        this.vehicleList = response;
        listingData = {
          vehicles: this.vehicleList,
        }
      }

      if (from == 'all') {
        this.allVehiclesList = response;
        listingData = {
          vehicles: this.allVehiclesList,
        }
      }

      this.SharingData.next(listingData);

    },
      (error: any) => {

        let listingData = {
          vehicles: [],
        }
        this.SharingData.next(listingData);

      });

  }

  addNewVehicle(payload) {
    console.log(payload);
    if (payload) {
      let apiUrl = `${apiRoutes.vehicle}/create`;
      this.apiCallMethodsService.post(apiUrl, payload).subscribe((response: any) => {
        if (response.succeeded == true) {
          this.alertService.successAlert(response.message[0]);
          setTimeout(() => {
            this.dialog.closeAll();
            this.vehicleListPage = 1;
            this.getVehiclesList(this.vehicleListPage, this.vehicleListRows, 'list');
          })
        }
        else {
          this.alertService.failureAlert(response.message[0]);
        }
      },
        (error: any) => {
          this.alertService.failureAlert(error.error.message[0]);

        })
    }


  }

  EditVehicle(payload, id) {
    if (payload) {
      let apiUrl = `${apiRoutes.vehicle}/${id}/modify`;

      this.apiCallMethodsService.put(apiUrl, payload).subscribe((response: any) => {
        if (response.succeeded == true) {
          this.alertService.successAlert(response.message[0]);
          setTimeout(() => {
            this.dialog.closeAll();
            this.getVehiclesList(this.vehicleListPage, this.vehicleListRows, 'list');
          })
        }
        else {
          this.alertService.failureAlert(response.message[0]);
        }
      },
        (error: any) => {
          this.alertService.failureAlert(error.error.message[0]);
        })
    }


  };

  deleteVehicle(id) {
    this.alertService.confirmAlert('You want to delete this vehicle ?').subscribe((userChoice) => {
      if (userChoice === 'confirm') {
        let apiUrl = `${apiRoutes.vehicle}/${id}/delete`;

        this.apiCallMethodsService.delete(apiUrl).subscribe((response: any) => {
          if (response.succeeded == true) {
            this.alertService.successAlert(response.message[0]);
            setTimeout(() => {
              this.dialog.closeAll();
              this.getVehiclesList(this.vehicleListPage, this.vehicleListRows, 'list')
            })
          }
          else {
            this.alertService.failureAlert(response.message[0]);
          }
        },
          (error: any) => {
            this.alertService.failureAlert(error.error.message[0]);
          })
      }
    }
    )
  }
  //DRIVER LIST
  getDriversList(pageNumber, rowsPerPage, from, filterOptions = null) {
    let name;
    let phoneNumber;
    if (filterOptions) {

      if (filterOptions != null) {
        if (filterOptions.name != null) {
          name = filterOptions.name;
        }
        else {
          phoneNumber = filterOptions.phoneNumber;
        }

      }
    }

    let apiUrl = `${apiRoutes.driver + '/list'}?pageNumber=${pageNumber}&pageSize=${rowsPerPage}&name${name ? '=' + name : ''}&phoneNumber${phoneNumber ? '=' + phoneNumber : ''}`;

    this.apiCallMethodsService.get(apiUrl).subscribe((response: any) => {

      let listingData;

      if (from == 'list') {
        this.driverList = response;
        listingData = {
          drivers: this.driverList,
        }
      }

      if (from == 'all') {
        this.allDriversList = response;
        listingData = {
          drivers: this.allDriversList,
        }
      }

      this.SharingData.next(listingData);

    },
      (error: any) => {

        let listingData = {
          drivers: [],
        }
        this.SharingData.next(listingData);

      });

  }

  addNewDriver(payload) {
    if (payload) {
      let apiUrl = `${apiRoutes.driver}/create`;
      this.apiCallMethodsService.post(apiUrl, payload).subscribe((response: any) => {
        if (response.succeeded == true) {
          this.alertService.successAlert(response.message[0]);
          setTimeout(() => {
            this.dialog.closeAll();
            this.getDriversList(this.driverListPage, this.driverListRows, 'list');
          })
        }
        else {
          this.alertService.failureAlert(response.message[0]);
        }
      }, (error: any) => {
        this.alertService.failureAlert(error.error.message[0]);
      }
      )
    }
  };
  editDriver(payload, id) {
    if (payload) {
      let apiUrl = `${apiRoutes.driver}/${id}/modify`;
      this.apiCallMethodsService.put(apiUrl, payload).subscribe((response: any) => {
        console.log(response);
        if (response.succeeded == true) {
          this.alertService.successAlert(response.message[0]);
          setTimeout(() => {
            this.dialog.closeAll();
            this.getDriversList(this.driverListPage, this.driverListRows, 'list');
          })
        }
        else {
          this.alertService.failureAlert(response.message[0]);
        }
      }, (error: any) => {
        this.alertService.failureAlert(error.error.message[0]);
      })
    }
  };
  deleteDriver(id) {
    let apiUrl = `${apiRoutes.driver}/${id}/delete`;
    this.alertService.confirmAlert('You want to delete this driver ?').subscribe((userChoice) => {
      if (userChoice === 'confirm') {
        this.apiCallMethodsService.delete(apiUrl).subscribe((response: any) => {
          if (response.succeeded == true) {
            this.alertService.successAlert(response.message[0]);
            setTimeout(() => {
              this.dialog.closeAll();
              this.getDriversList(this.driverListPage, this.driverListRows, 'list')
            })
          }
          else {
            this.alertService.failureAlert(response.message[0]);
          }
        }, (error: any) => {
          this.alertService.failureAlert(error.error.message[0])
        })
      }
    })
  }

  // PRICING INVENTORY 
  getPricingInventoryList(pageNumber, rowsPerPage, filterOptions = null) {

    let searchValue;
    let searchBy;

    if (filterOptions) {
      if (filterOptions != null) {
        searchValue = filterOptions?.searchValue;
        searchBy = filterOptions?.searchBy;
      }
    }

    let apiUrl = `${apiRoutes.pricingInventory}/list?pageNumber=${pageNumber}&pageSize=${rowsPerPage}`;

    this.apiCallMethodsService.get(apiUrl).subscribe((response: any) => {

      let listingData;

      this.pricingList = response;
      listingData = {
        pricing: this.pricingList,
      }

      this.SharingData.next(listingData);

    },
      (error: any) => {

        let listingData = {
          pricing: [],
        }
        this.SharingData.next(listingData);

      });

  }

  addPricing(payload) {
    if (payload) {
      let apiUrl = `${apiRoutes.pricingInventory}/create`;
      this.apiCallMethodsService.post(apiUrl, payload).subscribe((response: any) => {
        if (response.succeeded == true) {
          this.alertService.successAlert(response.message[0]);
          setTimeout(() => {
            this.dialog.closeAll();
            this.getPricingInventoryList(this.pricingListPage, this.pricingListRows);
          })
        }
        else {
          this.alertService.failureAlert(response.message[0]);
        }
      }, (error: any) => {
        this.alertService.failureAlert(error.error.message[0]);
      }
      )
    }
  };

  deletePricing(id) {
    let apiUrl = `${apiRoutes.pricingInventory}/${id}/delete`;
    this.alertService.confirmAlert('You want to delete this pricing ?').subscribe((userChoice) => {
      if (userChoice === 'confirm') {
        this.apiCallMethodsService.delete(apiUrl).subscribe((response: any) => {
          if (response.succeeded == true) {
            this.alertService.successAlert(response.message[0]);
            setTimeout(() => {
              this.dialog.closeAll();
              this.getPricingInventoryList(this.pricingListPage, this.pricingListRows);
            })
          }
          else {
            this.alertService.failureAlert(response.message[0]);
          }
        }, (error: any) => {
          this.alertService.failureAlert(error.error.message[0])
        })
      }
    })
  }

  editPricing(payload, id) {
    if (payload) {
      let apiUrl = `${apiRoutes.pricingInventory}/${id}/edit`;
      this.apiCallMethodsService.put(apiUrl, payload).subscribe((response: any) => {
        console.log(response);
        if (response.succeeded == true) {
          this.alertService.successAlert(response.message[0]);
          setTimeout(() => {
            this.dialog.closeAll();
            this.getPricingInventoryList(this.pricingListPage, this.pricingListRows);
          })
        }
        else {
          this.alertService.failureAlert(response.message[0]);
        }
      }, (error: any) => {
        this.alertService.failureAlert(error.error.message[0]);
      })
    }
  };

  // STORAGE INVENTORY 
  getStorageInventoryList(pageNumber, rowsPerPage, filterOptions = null) {

    // let searchValue;
    // let searchBy;

    // if (filterOptions) {
    //   if (filterOptions != null) {
    //     searchValue = filterOptions?.searchValue;
    //     searchBy = filterOptions?.searchBy;
    //   }
    // }

    let apiUrl = `${apiRoutes.storageInventory}/list?pageNumber=${pageNumber}&pageSize=${rowsPerPage}`;

    this.apiCallMethodsService.get(apiUrl).subscribe((response: any) => {

      let listingData;

      this.storageList = response;
      listingData = {
        storage: this.storageList,
      }

      this.SharingData.next(listingData);

    },
      (error: any) => {

        let listingData = {
          storage: [],
        }
        this.SharingData.next(listingData);

      });

  }

  addStorage(payload) {
    if (payload) {
      let apiUrl = `${apiRoutes.storageInventory}/create`;
      this.apiCallMethodsService.post(apiUrl, payload).subscribe((response: any) => {
        if (response.succeeded == true) {
          this.alertService.successAlert(response.message[0]);
          setTimeout(() => {
            this.dialog.closeAll();
            this.getStorageInventoryList(this.storageListPage, this.storageListRows);
          })
        }
        else {
          this.alertService.failureAlert(response.message[0]);
        }
      }, (error: any) => {
        this.alertService.failureAlert(error.error.message[0]);
      }
      )
    }
  };

  editStorage(payload, id) {
    if (payload) {
      let apiUrl = `${apiRoutes.storageInventory}/${id}/modify`;
      this.apiCallMethodsService.put(apiUrl, payload).subscribe((response: any) => {
        console.log(response);
        if (response.succeeded == true) {
          this.alertService.successAlert(response.message[0]);
          setTimeout(() => {
            this.dialog.closeAll();
            this.getStorageInventoryList(this.storageListPage, this.storageListRows);
          })
        }
        else {
          this.alertService.failureAlert(response.message[0]);
        }
      }, (error: any) => {
        this.alertService.failureAlert(error.error.message[0]);
      })
    }
  };

  deleteStorage(id) {
    let apiUrl = `${apiRoutes.storageInventory}/${id}/delete`;
    this.alertService.confirmAlert('You want to delete this storage ?').subscribe((userChoice) => {
      if (userChoice === 'confirm') {
        this.apiCallMethodsService.delete(apiUrl).subscribe((response: any) => {
          if (response.succeeded == true) {
            this.alertService.successAlert(response.message[0]);
            setTimeout(() => {
              this.dialog.closeAll();
              this.getStorageInventoryList(this.storageListPage, this.storageListRows);
            })
          }
          else {
            this.alertService.failureAlert(response.message[0]);
          }
        }, (error: any) => {
          this.alertService.failureAlert(error.error.message[0])
        })
      }
    })
  }

  // SUPPLIER
  getSupplierList(pageNumber, rowsPerPage, filterOptions = null) {


console.log(filterOptions);

    
    let apiUrl = `${apiRoutes.supplier}/list?status${filterOptions?.status ? '=' + filterOptions?.status : ''}&fieldName${filterOptions?.fieldName ? '=' + filterOptions?.fieldName : ''}&sortBy${filterOptions?.orderBy ? '=' + filterOptions?.orderBy : ''}&pageNumber=${pageNumber}&supplierName${filterOptions?.name ? '=' + filterOptions?.name : ''}&pageSize=${rowsPerPage}`;

    this.apiCallMethodsService.get(apiUrl).subscribe((response: any) => {

      let listingData;
      this.loaderService.stop();

      this.supplierList = response;
      listingData = {
        supplier: this.supplierList,
      }

      this.SharingData.next(listingData);

    },
      (error: any) => {
this.loaderService.stop();
        let listingData = {
          supplier: [],
        }
        this.SharingData.next(listingData);

      });

  }


  addSupplier(payload) {
    if (payload) {
      let apiUrl = `${apiRoutes.supplier}/create`;
      this.apiCallMethodsService.post(apiUrl, payload).subscribe((response: any) => {
        if (response.succeeded == true) {
          this.alertService.successAlert(response.message[0]);
          setTimeout(() => {
            this.router.navigate(['/supplier']);
            this.getSupplierList(this.supplierListPage, this.supplierListRows, null);
          })
        }
        else {
          this.alertService.failureAlert(response.message[0]);
        }
      }, (error: any) => {
        this.alertService.failureAlert(error.error.message[0]);
      }
      )
    }
  }

  getOneSupplier(id) {
    if (id) {
      let apiUrl = `${apiRoutes.supplier}/${id}/details`;
      this.apiCallMethodsService.get(apiUrl).subscribe((response: any) => {
        if (response.succeeded == true) {
          let supplierDetails;
          this.loaderService.stop();
    
          this.supplierDetails = response;
          supplierDetails = {
            supplierDetails: this.supplierDetails,
          }
    
          this.SharingData.next(supplierDetails);
        }
       
      }, (error: any) => {
        let supplierDetails = {
          supplierDetails: [],
        }
        this.SharingData.next(supplierDetails);      }
      )
    }
  };

  editSupplier(payload, id, details) {
    if (payload) {
      let apiUrl = `${apiRoutes.supplier}/edit/${id}`;
      
      this.apiCallMethodsService.put(apiUrl, payload).subscribe((response: any) => {
        if (response.succeeded == true) {
          this.alertService.successAlert(response.message[0]);
          
            if(details){
              if(details === 'false'){

              this.router.navigate(['/supplier']);
              this.getSupplierList(this.supplierListPage, this.supplierListRows, null);
              }
              if(details === 'true'){
                this.router.navigate(['/supplier-details', id, 'supplier-profile'])
              }
            } 
        }
        else {
          this.alertService.failureAlert(response.message[0]);
        }
      }, (error: any) => {
        this.alertService.failureAlert(error.error.message[0]);
      }
      )
    }
  }

  downloadSupplierCsv(filterOptions = null) {
    let apiUrl = `${apiRoutes.supplier}/export?supplierName${filterOptions?.name ? '=' + filterOptions?.name : ''}&fieldName${filterOptions?.fieldName ? '=' + filterOptions?.fieldName : ''}&sortBy${filterOptions?.orderBy ? '=' + filterOptions?.orderBy : ''}&status${filterOptions?.status ? '=' + filterOptions?.status : ''}`;
  


    this.apiCallMethodsService.getBlob(apiUrl).subscribe((response: Blob) => {

      const blob = new Blob([response], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'supplier_list.csv';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);

    }, (error: any) => {
      console.log(error.error.message[0]);
    })
  }

  deleteSupplier(id) {
    let payload;
    let message;

    let apiUrl = `${apiRoutes.supplier}/delete`;
    if (typeof id == 'object') {
      payload = {
        supplierIds: id,
      }
      message = 'You want to delete these suppliers ?';
    }
    else {
      payload = {
        supplierIds: [id],
      }
      message = 'You want to delete this supplier ?';

    }

    this.alertService.confirmAlert(message).subscribe((userChoice) => {
      if (userChoice === 'confirm') {

        this.apiCallMethodsService.put(apiUrl, payload).subscribe((response: any) => {
          if (response.succeeded == true) {
            this.alertService.successAlert(response.message[0]);
            setTimeout(() => {
              this.getSupplierList(this.supplierListPage, this.supplierListRows, null);
            })
          }
          else {
            this.alertService.failureAlert(response.message[0]);
          }
        }, (error: any) => {
          this.alertService.failureAlert(error.error.message[0])
        })
      }
    })
  }

}
