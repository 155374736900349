import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { AuthService } from '../../authentication/services/auth-service/auth.service';
import { Router } from '@angular/router';
import { NEVER, Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiCallMethodsService } from '../services/api-call-methods-service/api-call-methods.service';
import { apiRoutes } from 'src/app/constants/apiRoutes';
@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {

  constructor(private authService:AuthService,private router:Router,private modalService: NgbModal, private apiCallMethodsService: ApiCallMethodsService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.authService.isAuthenticated()) {
      request = this.addToken(request, this.authService.getToken());
    }
    else{
      request = this.addToken(request, null);
    }

    return next.handle(request).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          return this.handle401Error(request, next, error);
        } else {
          return throwError(error);
        }
      })
    );
  }

  private addToken(
    request: HttpRequest<any>,
    token: string = null,
  ): HttpRequest<any> {
    let headers = {};
  
    if (token !== null) {
      headers['Authorization'] = `Bearer ${token}`;
    }
  
    return request.clone({
      setHeaders: headers,
    });
  }

  private handle401Error( request: HttpRequest<any>, next: HttpHandler ,error :any): Observable<any>
  {

    let sessionDetail = this.authService.getSessionDetail();
    if(sessionDetail){
      let refreshTokenPayload = {};
      refreshTokenPayload['token'] = sessionDetail['token'];
      refreshTokenPayload['refreshToken'] = sessionDetail['refreshToken'];

      return this.apiCallMethodsService.post(apiRoutes.generateNewToken,refreshTokenPayload).pipe(switchMap((response: any)=>{
        if(response.succeeded == true)
        {
          this.authService.setToken(response.data);
          return next.handle(this.addToken(request, this.authService.getToken()));
        }
      }), catchError((err) => {
        return throwError(() => err);
      }))
    }

    else{
      this.router.navigateByUrl('/login');
      this.authService.removeToken();
      return throwError(error);
    }

  }

}