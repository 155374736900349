import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { HeaderComponent } from './components/header/header.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatListModule} from '@angular/material/list';
import {MatIconModule} from '@angular/material/icon';
import {MatRippleModule} from '@angular/material/core';
import { DateFilterComponent } from './components/date-filter/date-filter.component';
import {MatMenuModule} from '@angular/material/menu';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { RouterModule } from '@angular/router';
import { FooterComponent } from './components/footer/footer.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import {MatPaginatorModule} from '@angular/material/paginator';
import { ConvertMoneyPipe } from './pipes/convert-money.pipe';
import { ImagePreviewPopupComponent } from './components/image-preview-popup/image-preview-popup.component';
import { MatDialogModule } from '@angular/material/dialog';
import { IntTelPickerComponent } from './components/int-tel-picker/int-tel-picker/int-tel-picker.component';
import { ImagePopupComponent } from './components/image-popup/image-popup.component';

@NgModule({
  declarations: [
    SidebarComponent,
    HeaderComponent,
    DateFilterComponent,
    FooterComponent,
    PaginatorComponent,
    ConvertMoneyPipe,
    ImagePreviewPopupComponent,
    IntTelPickerComponent,
    ImagePopupComponent
  ],
  imports: [
    CommonModule,
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    MatRippleModule,
    MatMenuModule,
    MatFormFieldModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    FormsModule,
    MatNativeDateModule,
    RouterModule,
    MatPaginatorModule,
    MatDialogModule
  ],
  exports: [
    SidebarComponent,
    HeaderComponent,
    DateFilterComponent,
    FooterComponent,
    PaginatorComponent,
    ConvertMoneyPipe,
    IntTelPickerComponent,
  ]
})
export class SharedModule { }
