import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './guards/auth-guard/auth-guard.guard';
import { AuthenticationModule } from './authentication/authentication.module';
import { LogoutGuardService } from './guards/logout-guard/logout-guard.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: '',
    pathMatch: 'full'
  },
  {
    path: '',
    loadChildren: () =>
      import('src/app/main/main.module').then(
        (m) => m.MainModule
      ),
    canActivate: [AuthGuardService]
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('src/app/authentication/authentication.module').then(
        (m) => m.AuthenticationModule
      ),
    canActivate: [LogoutGuardService]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{paramsInheritanceStrategy: 'always'})],
  exports: [RouterModule,AuthenticationModule]
})
export class AppRoutingModule { }
