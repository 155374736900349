import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TripDetailService } from '../../services/trip-detail.service';
import { ImagePreviewPopupComponent } from 'src/app/shared/components/image-preview-popup/image-preview-popup.component';

@Component({
  selector: 'app-document-add-edit-popup',
  templateUrl: './document-add-edit-popup.component.html',
  styleUrls: ['./document-add-edit-popup.component.scss']
})
export class DocumentAddEditPopupComponent {

  documents: any = null;
  uploadedDocument: any = null;
  uploadedEditDocument: any = null;
  uploadedImageUrl: any = null;
  uploadedEditImageUrl: any = null;
  documentId: any = null;
  allowSingleDocument: any = false;

  constructor(@Inject(MAT_DIALOG_DATA) public incomingData: any,private tripDetailService: TripDetailService,
  private dialogRef: MatDialogRef<DocumentAddEditPopupComponent>,private dialog: MatDialog){

    this.dialogRef.afterClosed().subscribe(() => {
      this.documentId = null;
      this.uploadedDocument = null;
      this.uploadedEditDocument = null;
      this.uploadedImageUrl = null;
      this.uploadedEditImageUrl = null;

      for(let i = 0; i < this.documents?.length; i++){
        this.documents[i]['newUrl'] = null;
        this.documents[i]['newImgSrc'] = null;
      }

    });

    this.documents = this.incomingData.documents;
    

    console.log(this.incomingData);

    if(this.incomingData?.type == 106){
      this.allowSingleDocument = true;
    }
    else{
      this.allowSingleDocument = false;
    }

  }

  openImagePreview(data) {
    this.dialogRef.close();
    const dialogRef = this.dialog.open(ImagePreviewPopupComponent,{minWidth:'65vw', maxWidth:'65vw', height:'90vh',autoFocus:false,panelClass:'doc-preview-popup',
    data: {
      url: data ,
      from: 'trip',
      returnData: this.incomingData
    }});-

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  selectDocument(event){

    this.documentId = null;

    for(let i = 0; i < this.documents.length; i++){
      this.documents[i]['newUrl'] = null;
      this.documents[i]['newImgSrc'] = null;
    }

    const file: File = event.target.files[0];
    if (file) {
      this.uploadedDocument = file;

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.uploadedImageUrl = reader.result;
      };

      console.log(this.uploadedDocument);
    }
  }

  selectEditedDocument(event,document){

    this.documentId = null;
    this.uploadedDocument = null;
    this.uploadedImageUrl = null;

    for(let i = 0; i < this.documents.length; i++){
      this.documents[i]['newUrl'] = null;
      this.documents[i]['newImgSrc'] = null;
    }

    const file: File = event.target.files[0];
    if (file) {
      document['newUrl'] = file;

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        document['newImgSrc'] = reader.result;
      };

      this.documentId = document?.id;

      console.log(this.documents);
    }
  }

  saveDocuments(){

    let formData = new FormData();

    let editedDocument = this.documents.find(x=> x.newUrl && x.newImgSrc);

    if(this.uploadedDocument && this.uploadedImageUrl){
      formData.append('file', this.uploadedDocument);
    }

    if(editedDocument && !this.uploadedDocument && !this.uploadedImageUrl){
      formData.append('file', editedDocument.newUrl);
    }

    let folder;
    let type;

    if(this.incomingData?.type == 103){
      folder = 'Invoice';
    }

    if(this.incomingData?.type == 104){
      folder = 'EWayBill';
    }

    if(this.incomingData?.type == 105){
      folder = 'MSDSBill';
    }

    if(this.incomingData?.type == 106){
      folder = 'POD';
    }

    let params = {
      folderName: folder,
      documentId : this.documentId ? this.documentId : null,
      documentType: this.incomingData?.type,
    }

    console.log(params);
    

    this.tripDetailService.addDocument(formData,params);

  }

  ngOnDestroy(){
  }

}
