import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { apiRoutes } from 'src/app/constants/apiRoutes';
import { MainService } from 'src/app/main/services/main-service/main.service';
import { AlertService } from 'src/app/shared/services/alert-service/alert.service';
import { ApiCallMethodsService } from 'src/app/shared/services/api-call-methods-service/api-call-methods.service';
import { CustomerDetailsService } from '../../customer-details/services/customer-details.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Injectable({
  providedIn: 'root'
})
export class TripDetailService {

  tripId: any = null;
  userId: any = null;
  SharingData = new Subject();
  tripDetails: any = null;
  trackingId: any = null;

  constructor(private apiCallMethodsService: ApiCallMethodsService, private activatedRoute: ActivatedRoute,
    private router: Router, private alertService: AlertService, private dialog: MatDialog,
    private mainService: MainService, private customerService: CustomerDetailsService, private loaderService: NgxUiLoaderService) {
  }

  getTripAndUserIds() {
    const idRegex = /\/trip-details\/([^/]+)\/([^/]+)\//;
    const match = this.router.url.match(idRegex);
    if (match && match.length > 2) {
      this.tripId = match[1];
      this.userId = match[2];
    }
  }

  getTripDetails() {

    this.getTripAndUserIds();

    if (this.tripId && this.userId) {

      let apiUrl = `${apiRoutes.customer}/trip-details?tripId=${this.tripId}&userId=${this.userId}`;

      this.apiCallMethodsService.get(apiUrl).subscribe((response: any) => {

        this.tripDetails = response.data;

        let data = {
          details: this.tripDetails
        }

        this.SharingData.next(data);

      })

    }

  }

  addNewEnquiry(payload, userId) {

    if (payload) {
      // console.log(payload)
      payload.dropLocation = payload?.dropLocationList[payload?.dropLocationList.length - 1].location
      let apiUrl = `${apiRoutes.enquiry}/create`;

      this.apiCallMethodsService.post(apiUrl, payload).subscribe((response: any) => {
        if (response.succeeded == true) {
          this.customerService.getEnquiresList(this.customerService.enquiryListPage, this.customerService.enquiryListRows);
          this.redirectAfterEnquiryAdd(response?.message[0], response?.data?.enquiryId, userId);
        }
        else {
          this.alertService.failureAlert(response.message[0]);
        }
      }, (error: any) => {
        this.alertService.failureAlert(error.error.message[0]);
      });

    }

  }

  redirectAfterEnquiryAdd(message, enquiryId, userId) {

    this.alertService.enquiryAddConfirmAlert(message).subscribe((userChoice) => {
      if (userChoice === 'confirm') {
        this.router.navigate(['/trip-details', enquiryId, userId, 'enquiry-details'])
      }
      if (userChoice === 'cancel') {
        this.router.navigate(['/customers-details', userId]);
      }
    }, (error: any) => {
    });

  }

  editLocationInfo(payload, id) {

    if (payload && id) {
      if (Object.keys(payload.pickupDate && payload.startDate && payload.endDate)) {
        const timestampInSeconds = Math.floor(new Date(payload.pickupDate).getTime() / 1000);
        const timestampInSeconds1 = Math.floor(new Date(payload.startDate).getTime() / 1000);
        const timestampInSeconds2 = Math.floor(new Date(payload.endDate).getTime() / 1000);
        payload.pickupDate = timestampInSeconds;
        payload.startDate = timestampInSeconds1;
        payload.endDate = timestampInSeconds2;
      }
      
      console.log(payload, 'payload')
      let apiUrl = `${apiRoutes.enquiry}/${id}/modify`;

      this.apiCallMethodsService.put(apiUrl, payload).subscribe((response: any) => {
        if (response.succeeded == true) {
          this.alertService.successAlert(response.message[0]);
          setTimeout(() => {
            this.getTripDetails();
          }, 2000)
        }
        else {
          this.alertService.failureAlert(response.message[0]);
        }
      }, (error: any) => {
        this.alertService.failureAlert(error.error.message[0]);
      });

    }

  }
  deleteLocation(id) {

    let apiUrl = `${apiRoutes.enquiry}/location/${id}/delete`;
    this.apiCallMethodsService.put(apiUrl, '').subscribe(
      (res) => {
        console.log('Delete success:', res);
        // Perform further actions, like updating the UI
      },
      (error) => {
        console.error('Delete failed:', error);
        // Handle the error, show a message to the user, etc.
      }
    );

  }
  editEnquiryDetails(payload, id) {

    if (payload && id) {

      let apiUrl = `${apiRoutes.enquiry}/${id}/modify-status`;
      this.loaderService.start();

      this.apiCallMethodsService.put(apiUrl, payload).subscribe((response: any) => {
        this.loaderService.stop();
        if (response.succeeded == true) {
          setTimeout(() => {
            this.alertService.successAlert(response.message[0]);
          }, 350);
          setTimeout(() => {
            this.getTripDetails();
            this.mainService.getEnquiresList(this.mainService.enquiryListPage, this.mainService.enquiryListRows);
          }, 2000)
        }
        else {
          this.alertService.failureAlert(response.message[0]);
        }
      }, (error: any) => {
        this.alertService.failureAlert(error.error.message[0]);
      });

    }

  }

  editOrderDetails(payload, id) {

    if (payload && id) {
      this.loaderService.start();

      let apiUrl = `${apiRoutes.order}/${id}/modify-status`;

      this.apiCallMethodsService.put(apiUrl, payload).subscribe((response: any) => {
        this.loaderService.stop();
        if (response.succeeded == true) {

          setTimeout(() => {
            this.alertService.successAlert(response.message[0]);
          }, 350);

          setTimeout(() => {
            this.getTripDetails();
            this.mainService.getOrdersList(this.mainService.orderListPage, this.mainService.orderListRows);
          }, 2000)
        }
        else {
          this.alertService.failureAlert(response.message[0]);

        }
      }, (error: any) => {
        this.loaderService.stop();
        this.alertService.failureAlert(error.error.message[0]);


      });

    }

  }

  //  DOCUMENT
  addDocument(payload, params) {

    if (payload && params) {

      let apiUrl = `${apiRoutes.customer}/${this.userId}/upload-documents?folderName=${params.folderName}&documentId=${params.documentId ? params.documentId : ''}&documentType=${params.documentType}&orderId=${this.tripDetails?.orderId}`

      this.apiCallMethodsService.post(apiUrl, payload).subscribe((response: any) => {
        if (response.succeeded == true) {
          this.alertService.successAlert(response.message[0]);
          setTimeout(() => {
            this.dialog.closeAll();
            this.getTripDetails();
          }, 2000)
        }
        else {
          this.alertService.failureAlert(response.message[0]);
        }
      }, (error: any) => {
        this.alertService.failureAlert(error.error.message[0]);
      });

    }

  }

  // PAYMENT
  addPaymentInfo(payload) {

    if (payload) {

      let apiUrl = `${apiRoutes.payment}/create`;

      payload['userId'] = this.userId;
      this.loaderService.start();

      this.apiCallMethodsService.post(apiUrl, payload).subscribe((response: any) => {
        this.loaderService.stop();
        if (response.succeeded == true) {
          setTimeout(() => {
            this.alertService.successAlert(response.message[0]);
          }, 350);
          setTimeout(() => {
            this.dialog.closeAll();

            this.getTripDetails();
          }, 2000)
        }
        else {
          this.alertService.failureAlert(response.message[0]);
        }
      }, (error: any) => {
        this.loaderService.stop();

        this.alertService.failureAlert(error.error.message[0]);
      });


    }

  }

  editPaymentInfo(payload, id) {

    if (payload && id) {

      let apiUrl = `${apiRoutes.payment}/${id}/modify`;

      payload['userId'] = this.userId;
      this.loaderService.start();

      this.apiCallMethodsService.put(apiUrl, payload).subscribe((response: any) => {
        this.loaderService.stop();
        if (response.succeeded == true) {
          setTimeout(() => {
            this.alertService.successAlert(response.message[0]);
          }, 350);

          setTimeout(() => {
            this.dialog.closeAll();

            this.getTripDetails();
          }, 2000)
        }
        else {
          this.alertService.failureAlert(response.message[0]);
        }
      }, (error: any) => {
        this.loaderService.stop();

        this.alertService.failureAlert(error.error.message[0]);
      });


    }

  }

  addPaymentHistory(payload) {

    if (payload) {
      this.loaderService.start();

      let apiUrl = `${apiRoutes.payment}/add-transaction`;

      payload['userId'] = this.userId;

      this.apiCallMethodsService.post(apiUrl, payload).subscribe((response: any) => {
        this.loaderService.stop();
        if (response.succeeded == true) {

          setTimeout(() => {
            this.alertService.successAlert(response.message[0]);
          }, 350);

          setTimeout(() => {
            this.dialog.closeAll();

            this.getTripDetails();
          }, 2000)
        }
        else {

          this.alertService.failureAlert(response.message[0]);
        }
      }, (error: any) => {
        this.loaderService.stop();

        this.alertService.failureAlert(error.error.message[0]);
      });


    }

  }

  editPaymentHistory(payload, id) {

    if (payload && id) {

      let apiUrl = `${apiRoutes.payment}/${id}/edit-transaction`;

      payload['userId'] = this.userId;

      this.apiCallMethodsService.put(apiUrl, payload).subscribe((response: any) => {
        if (response.succeeded == true) {
          setTimeout(() => {
            this.alertService.successAlert(response.message[0]);
          }, 350);

          setTimeout(() => {
            this.getTripDetails();
          }, 2000)
        }
        else {
          this.alertService.failureAlert(response.message[0]);
        }
      }, (error: any) => {
        this.alertService.failureAlert(error.error.message[0]);
      });

    }

  }

  deletePaymentHistory(id) {

    this.alertService.confirmAlert('You want to delete this payment history ?').subscribe((userChoice) => {
      if (userChoice === 'confirm') {
        let apiUrl = `${apiRoutes.payment}/${id}/delete-transaction`;
        this.apiCallMethodsService.delete(apiUrl).subscribe((response: any) => {
          if (response.succeeded == true) {
            this.alertService.successAlert(response.message[0]);
            setTimeout(() => {
              this.dialog.closeAll();
              this.getTripDetails();
            }, 2000);
          }
          else {
            this.alertService.failureAlert(response.message[0]);
          }
        })
      }
    }, (error: any) => {
      this.alertService.failureAlert(error.error.message[0]);
    });
  }

  // TRACKING
  editTrackingDetails(payload, orderId) {
    console.log(true);

    if (payload && orderId) {

      console.log(true);
this.loaderService.start();

      let apiUrl = `${apiRoutes.order}/${orderId}/tracking`;

      this.apiCallMethodsService.put(apiUrl, payload).subscribe((response: any) => {
     this.loaderService.stop();

        if (response.succeeded == true) {
          setTimeout(() => {
      
            this.alertService.successAlert(response.message[0]);
          }, 350);
          setTimeout(() => {
            this.getTripDetails();
            this.mainService.getOrdersList(this.mainService.orderListPage, this.mainService.orderListRows);
          }, 2000)
        }
        else {
          this.alertService.failureAlert(response.message[0]);
        }
      }, (error: any) => {
        this.loaderService.stop();

        this.alertService.failureAlert(error.error.message[0]);
      });

    }
  }
  


  // SUPPLIER DETAILS

  supplierDetails(payload, id) {

    if (payload) {
      this.loaderService.start();

      let apiUrl = `${apiRoutes.supplier}/${id}/trip/assign`;

      this.apiCallMethodsService.put(apiUrl, payload).subscribe((response: any) => {
        this.loaderService.stop();
        if (response.succeeded == true) {
          setTimeout(() => {
            this.alertService.successAlert(response.message[0]);
          }, 350);
          setTimeout(() => {
            this.getTripDetails();
          }, 2000)
        }
        else {

          this.alertService.failureAlert(response.message[0]);
        }
      }, (error: any) => {
        this.loaderService.stop();

        this.alertService.failureAlert(error.error.message[0]);
      });

    }

  }
}
