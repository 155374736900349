import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DocumentAddEditPopupComponent } from 'src/app/main/modules/trip-details/components/document-add-edit-popup/document-add-edit-popup.component';

@Component({
  selector: 'app-image-preview-popup',
  templateUrl: './image-preview-popup.component.html',
  styleUrls: ['./image-preview-popup.component.scss']
})
export class ImagePreviewPopupComponent {

  from: any = null;
  isPdf: any = null;

  constructor(private dialogRef: MatDialogRef<ImagePreviewPopupComponent>,@Inject(MAT_DIALOG_DATA) public incomingData: any,
  private sanitizer: DomSanitizer,private dialog: MatDialog){

    this.dialogRef.afterClosed().subscribe(() => {

      if(this.incomingData?.from == 'trip'){

        const dialogRef = this.dialog.open(DocumentAddEditPopupComponent,{minWidth:'700px', maxWidth: '700px',maxHeight:'90vh',autoFocus:false,
        data: this.incomingData?.returnData
        });

      }

    });

    console.log(this.incomingData);

    if(this.incomingData?.url.endsWith('.pdf')){
      this.isPdf = true;
    }
    else{
      this.isPdf = false;
    }

  }

  getSafeUrl(url): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

}
