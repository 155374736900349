<div class="pagination-design">

    <div class="d-flex justify-content-between align-items-center pagination-end">
    
        <p class="my-0 px-2 paginator-text" *ngIf="totalItems">
            Showing {{ startIndex + 1 }} - {{ endIndex }} of {{ totalItems }} entries
        </p>
    
        <p class="my-0 px-2 paginator-text" *ngIf="!totalItems">
            Showing 0 - 0 of 0 entries
        </p>
    
        <mat-paginator
        [length]="totalItems"
        [pageSize]="pageSize"
        [pageIndex]="pageIndex"
        [pageSizeOptions]="pageSizeOptions"
        (page)="pageChanged.emit($event)"
        aria-label="Select page"
        ></mat-paginator>
    
    </div>
</div>