<div class=" responsive-hide">

    <header class="d-flex align-items-center justify-content-between py-2">

        <!-- <div class="heading-text">
            <h1 class="m-0">{{heading}}</h1>
        </div> -->

        <!-- <div class="user d-flex align-items-center" mat-ripple [matMenuTriggerFor]="menu">
            <img src="../../../../assets/images/empty-user-picture.png" alt="">
            <h2 class="m-0">Jaspinder Singh</h2>
            <mat-icon>keyboard_arrow_down</mat-icon>
        </div> -->

        <div>
            <h1 class="m-0">{{heading}}</h1>
        </div>
        <div class="d-flex align-items-center justify-content-between cursor-pointer"  [matMenuTriggerFor]="menu">

            <div class="profile" mat-ripple>
                J
            </div>
            <p class="profile-name ms-2 mb-0">{{userData?.userName}}</p>
            <div class="ms-2">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4 6L8 10L12 6" stroke="#262A2E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    
            </div>
        </div>

        <mat-menu #menu="matMenu" class="user-menu" xPosition="before">
            <button mat-menu-item (click)="logout()"><mat-icon>logout</mat-icon>Logout</button>
        </mat-menu>

    </header>
</div>

<!-- for responsive -->
<div class=" responsive-show">

    <header class="d-flex align-items-center justify-content-between">
        <div class="container">
            <div class="row">
                <div class="col-3 p-0">
                    <div class="d-flex align-items-center">

                        <mat-icon class="hamburger-icon" (click)="openSidebar()">menu</mat-icon>
                        <div>
                            <h1 class="m-0 ms-2">{{heading}}</h1>
                        </div>
                    </div>
                </div>
                <div class="col-9">
                    <div class=" d-flex align-items-center justify-content-end" [matMenuTriggerFor]="menu">
                        <div class="profile" mat-ripple>
                            J
                        </div>
                        <p class="profile-name ms-2 mb-0">{{userData?.userName}}</p>
                        <div class="ms-2">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4 6L8 10L12 6" stroke="#262A2E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                
                        </div>
                    </div>

                    <mat-menu #menu="matMenu" class="user-menu" xPosition="before">
                        <button mat-menu-item (click)="logout()"><mat-icon>logout</mat-icon>Logout</button>
                    </mat-menu>
                </div>
            </div>
        </div>



    </header>
</div>

<div class="overlay" (click)="closeSidebar()"></div>