<div class="d-flex flex-wrap login-page">

    <div class="col-lg-7">
        <div class="login-picture h-100v d-flex justify-content-center align-items-center position-relative">
            <img src="../../../../assets/images/login-picture.png" alt="login-picture">
            <h4>Copyright &#64;2024 Frizit</h4>
        </div>
    </div>

    <div class="col-lg-5">

        <form [formGroup]="loginForm" class="login-form h-100v  d-flex justify-content-center align-items-center">

            <div class="login-body w-100 text-center">

                <img src="../../../../assets/images/logo.png" alt="">
                <h2>Welcome to <br> Frizit</h2>

                <div class="login-fields">

                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Email ID</mat-label>
                        <input type="email" formControlName="userName" matInput placeholder="Enter your email id">
                    </mat-form-field>

                    <mat-form-field class="w-100 mt-4 password position-relative" appearance="outline">
                        <mat-label>Password</mat-label>
                        <input [type]="isDisplayPassword ? 'text' : 'password'" formControlName="password" matInput placeholder="Enter your password">
                        <mat-icon mat-ripple (click)="showPassword()">{{isDisplayPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </mat-form-field>

                    <!-- <div class="text-end forgot-pass mt-2">
                        <a class="cursor-pointer">Forgot Password?</a>
                    </div> -->

                    <button mat-button class="primary-btn w-100 mt-5" (click)="submitLogin()" [disabled]="loginForm.invalid">LOGIN</button>

                </div>

            </div>

        </form>

    </div>

</div>