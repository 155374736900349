import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor() { }

  setToken(tokenDetail)
  {
    localStorage.setItem('token',JSON.stringify(tokenDetail));
  }

  getToken(): string {
    const token = localStorage.getItem('token');
    return token ? JSON.parse(token).token : ``;
  }

  getSessionDetail(){
    return localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')) : ``;
  }

  removeToken()
  {
     localStorage.removeItem("token");
  }

  isAuthenticated(): boolean {
    const token = this.getToken();
    return token.length > 0 ? true : false;
  }
  
}
