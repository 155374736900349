import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from './shared/shared.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HttpTokenInterceptor } from './shared/interceptor/http-token.interceptor';
import { DatePipe } from '@angular/common';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { DashboardService } from './main/services/dashboard-service/dashboard.service';
import {Amplify} from 'aws-amplify';
import { AmplifyAuthenticatorModule, AuthenticatorService } from '@aws-amplify/ui-angular';
import { environment } from '../environments/environment';
// Amplify.configure({
//   Auth: {
//     identityPoolId: environment.aws.identityPoolId, 
//     region: environment.aws.region,
//     userPoolId: environment.aws.userPoolId,
//     userPoolWebClientId: environment.aws.userPoolWebClientId,
//   },
//   Storage: {
//     bucket: environment.aws.s3.bucket,
//     region: environment.aws.s3.region,
//   }
// });


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgbModule,
    SharedModule,
    HttpClientModule,
    MatDialogModule
  ],
  providers: [ {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpTokenInterceptor,
    multi: true,
  },
  {
    provide: MatDialogRef,
    useValue: {}
  },
  DashboardService,
  DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
