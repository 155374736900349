export const apiRoutes = {
    login: 'identity/admin-login',
    generateNewToken: 'identity/admin/generate-token',
    customer: 'customer',
    enquiry: 'enquiry',
    order: 'order',
    dashboard: 'dashboard',
    vehicle: 'vehicle',
    driver: 'driver',
    loadQuantity: 'load-quantity',
    vehicleCapacity: 'vehicle-capacity',
    productTypes: 'product-type',
    payment: 'payment',
    uploadProfilePic: 'upload-file',
    pricingInventory: 'price-inventory',
    storageInventory: 'storage',
    supplier: 'supplier',
    reefer: 'reefer_systems',

}