import { EventEmitter, Injectable, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class HeadingGeneratorService {

  @Output() headingChanged : EventEmitter<any> = new EventEmitter();
  heading: any = null;
  
  constructor(private router: Router) { 

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        
        if(event.urlAfterRedirects.includes('dashboard')){
          this.heading = 'Dashboard';
        }

        if(event.urlAfterRedirects.includes('users')){
          this.heading = '';
        }

        if(event.urlAfterRedirects.includes('customers-details')){
          this.heading = 'User Details';
        }

        if(event.urlAfterRedirects.includes('enquiries')){
          this.heading = '';
        }

        if(event.urlAfterRedirects.includes('supplier')){
          this.heading = '';
        }

        if(event.urlAfterRedirects.includes('storage-enquiries')){
          this.heading = '';
        }
        
        if(event.urlAfterRedirects.includes('add-enquiry')){
          this.heading = 'Add Enquiry';
        }

        if(event.urlAfterRedirects.includes('orders')){
          this.heading = '';
        }

        if(event.urlAfterRedirects.includes('vehicles')){
          this.heading = '';
        }

        if(event.urlAfterRedirects.includes('drivers')){
          this.heading = '';
        }

        if(event.urlAfterRedirects.includes('pricing-inventory')){
          this.heading = '';
        }

        if(event.urlAfterRedirects.includes('storage-inventory')){
          this.heading = '';
        }

        if(event.urlAfterRedirects.includes('enquiry-type')){
          this.heading = '';
        }

        if(event.url.includes('master-data')){
          this.heading = '';
        }

        if(event.urlAfterRedirects.includes('trip-details')){
          this.heading = 'Trip Details';
        }

        if(event.urlAfterRedirects.includes('storage-enquiry-details')){
          this.heading = 'Storage Details';
        }

        console.log(this.heading);

        if(event.urlAfterRedirects.includes('users') || event.urlAfterRedirects.includes('enquiries') || event.urlAfterRedirects.includes('orders') || event.urlAfterRedirects.includes('supplier') || event.urlAfterRedirects.includes('vehicles') || event.urlAfterRedirects.includes('drivers') 
          || event.urlAfterRedirects.includes('pricing-inventory') || event.urlAfterRedirects.includes('storage-inventory') || event.urlAfterRedirects.includes('enquiry-type') || event.urlAfterRedirects.includes('storage-enquiries') || event.urlAfterRedirects.includes('master-data')){
          this.headingChanged.emit(this.heading);
        }
        else{
          this.headingChanged.emit(this.heading  ? this.heading : 'Dashboard');
        }

      }
    });

  }
  
}
