export const environment = {
    production: false,
    apiUrl: 'https://devapi.frizit.in/',

    aws: {
      region: 'ap-south-1',
      accessKeyId: 'AKIAQ3EGVEWIUXS434V7',
      secretAccessKey: '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',
      bucketName: 'frizit-dev-s3',
      // userPoolId: 'ap-south-1_5UdkHmsq6',
      // userPoolWebClientId: '6b5aiee2ul9o0rlmeb4f02opgo',
      // identityPoolId: 'ap-south-1:1195860b-263b-4bdb-b957-c6540b55429a',
      // s3: {
      //   bucket: 'frizit-dev-s3',
      //   region: 'ap-south-1',
      // }
    }

  };