import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { apiRoutes } from 'src/app/constants/apiRoutes';
import { AlertService } from 'src/app/shared/services/alert-service/alert.service';
import { ApiCallMethodsService } from 'src/app/shared/services/api-call-methods-service/api-call-methods.service';
import { SharedService } from 'src/app/shared/services/shared-service/shared.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  // DASHBOARD
  DashboardSharingData = new Subject();
  filterStart: any = null;
  filterEnd: any = null;
  dashboardOverview: any = null;
  latestPendingEnquiries: any = null;
  latestPendingOrders: any = null;

  constructor(private apiCallMethodsService: ApiCallMethodsService,private matDialog: MatDialog,private alertService: AlertService,
    private sharedService: SharedService) {

      this.sharedService.dateFilterUpdated.subscribe((data:any)=>{

        this.filterStart = data?.start;
        this.filterEnd = data?.end;
        
        if(this.dashboardOverview && data?.from == 'overview'){
          this.getDashboardOverview();
        }

        if(this.latestPendingEnquiries && data?.from == 'enquiry'){
          this.getPendingEnquiries();
        }

        if(this.latestPendingOrders && data?.from == 'order'){
          this.getPendingOrders();
        }

      });

  }

  getDashboardOverview(){

    this.apiCallMethodsService.get(apiRoutes.dashboard + '/overview' + '?startedAt=' + this.filterStart + '&endedAt=' + this.filterEnd).subscribe((response: any)=>{
      this.dashboardOverview = response?.data;

      let dashboardData = {
        overview: this.dashboardOverview,
      }

      this.DashboardSharingData.next(dashboardData);

    },(error:any)=>{
      console.log(error);
    })

  }

  getPendingEnquiries(){

    this.apiCallMethodsService.get(apiRoutes.dashboard + '/latest-pending-enquiries' + '?startedAt=' + this.filterStart + '&endedAt=' + this.filterEnd).subscribe((response: any)=>{
      this.latestPendingEnquiries = response;

      let dashboardData = {
        enquiries: this.latestPendingEnquiries,
      }

      this.DashboardSharingData.next(dashboardData);

    },(error:any)=>{
      console.log(error);
    })

  }

  getPendingOrders(){

    this.apiCallMethodsService.get(apiRoutes.dashboard + '/latest-pending-orders' + '?startedAt=' + this.filterStart + '&endedAt=' + this.filterEnd).subscribe((response: any)=>{
      this.latestPendingOrders = response;

      let dashboardData = {
        orders: this.latestPendingOrders,
      }

      this.DashboardSharingData.next(dashboardData);

    },(error:any)=>{
      console.log(error);
    })

  }

}
