import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { apiRoutes } from 'src/app/constants/apiRoutes';
import { AlertService } from 'src/app/shared/services/alert-service/alert.service';
import { ApiCallMethodsService } from 'src/app/shared/services/api-call-methods-service/api-call-methods.service';
import { AuthService } from '../../services/auth-service/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  loginForm: FormGroup;
  isDisplayPassword: boolean = false;

  constructor(private formBuilder: FormBuilder,
    private apiCallMethodsService: ApiCallMethodsService,
    private alertService: AlertService,
    private router: Router,
    private authService: AuthService) {

      this.loginForm = this.formBuilder.group({
        userName: new FormControl('',Validators.compose([Validators.required,Validators.pattern('^[^\\s@]+@[^@\\s]+\\.[^@\\s]+$')])),
        password: new FormControl('',Validators.compose([Validators.required])),
      });

  }

  showPassword(){
    this.isDisplayPassword = !this.isDisplayPassword;
  }

  submitLogin()
  {
       this.apiCallMethodsService.post(apiRoutes.login,this.loginForm.value).subscribe((response:any)=>{
           if(response.succeeded == true)
           {
             this.alertService.successAlert(response.message[0]);
             setTimeout(()=>{
               this.authService.setToken(response.data);
               this.router.navigateByUrl('dashboard',{replaceUrl: true});
             },2000)
           }
           else{
             this.alertService.failureAlert(response.message[0]);
           }
       },(error:any)=>{
       })
  }

}
