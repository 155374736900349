<div class="main-layout d-flex">

    <div class="sidebar" *ngIf="authService.isAuthenticated()">
        <app-sidebar></app-sidebar>
    </div>

    <div class="w-100 main-content-container" [ngClass]="{'p-0' : !authService.isAuthenticated()}">

        <app-header *ngIf="authService.isAuthenticated()"></app-header>

        <div class="main-content">
            <div class="body-content" [ngClass]="{'h-100v': !authService.isAuthenticated(), 'p-0' : !authService.isAuthenticated()}">
                <router-outlet></router-outlet>
                <app-footer *ngIf="authService.isAuthenticated()"></app-footer>
            </div>
        </div>
    </div>

</div>