import { Injectable } from '@angular/core';
import { CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from 'src/app/authentication/services/auth-service/auth.service';

@Injectable({
  providedIn: 'root'
})
export class LogoutGuardService implements CanActivate{


  constructor(public authService:AuthService,
    private router:Router) { }

    canActivate(route:any, state: RouterStateSnapshot) {
    let token = this.authService.getToken();
    if(!token)
    {
      return true;
    }
    else
    {
      this.router.navigateByUrl('dashboard',{ replaceUrl: true });
      return false;
    }
  }
}
