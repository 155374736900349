import { Component } from '@angular/core';
import { HeadingGeneratorService } from '../../service/heading-generator/heading-generator.service';
import { AuthService } from 'src/app/authentication/services/auth-service/auth.service';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  heading: any = null;
  userData: any = null;

  constructor(private headingGeneratorService: HeadingGeneratorService, private authService: AuthService, private router: Router) {

    if (this.headingGeneratorService.heading) {
      this.heading = this.headingGeneratorService.heading;
    }

    this.headingGeneratorService.headingChanged.subscribe((data: any) => {
      this.heading = data;
    });
    this.userData = this.authService?.getSessionDetail();
    console.log(authService?.getSessionDetail());


  }

  openSidebar() {
    document.querySelector('.sidebar-content').classList.add('mobile-open');
    document.querySelector('.overlay').classList.add('mobile-open');
  }

  closeSidebar() {
    document.querySelector('.sidebar-content').classList.remove('mobile-open');
    document.querySelector('.overlay').classList.remove('mobile-open');
  }

  logout() {
    this.authService.removeToken();
    this.router.navigateByUrl('login', { replaceUrl: true });
  }

}
