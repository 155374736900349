import { Component } from '@angular/core';
import { AuthService } from './authentication/services/auth-service/auth.service';
import { NavigationEnd, Router } from '@angular/router';
import { SharedService } from './shared/services/shared-service/shared.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(public authService: AuthService,private router: Router, private sharedService: SharedService){

    // this.authService.removeToken();  

    this.router.events.subscribe(event => {

      if (event instanceof NavigationEnd) {

        console.log(this.sharedService.isHeaderClosed);

        setTimeout(() => {
          if(this.sharedService.isHeaderClosed){
            document.querySelector('.header-toggle-icon')?.classList?.add('header-hide');
          }
          else{
            document.querySelector('.header-toggle-icon')?.classList?.remove('header-hide');
          }
        }, 10);

      }

    });

  }

}
