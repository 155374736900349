<div class="">
    <div class="image-popup">
        <div class="d-flex align-items-center justify-content-between mb-3">
            <p class="mb-3">{{imgUrlLink?.fileName}}</p>
            <div class="cursor-pointer" (click)="onNoClick()">
            <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M21.5 7.5L7.5 21.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M7.5 7.5L21.5 21.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
        </div>

        <div class="big-image">
            <img src="{{imgUrlLink?.imageUrl}}" alt="" class="w-100 h-100">
        </div>


    </div>
</div>