import { Routes } from "@angular/router";
import { LoginComponent } from "../components/login/login.component";
import { LogoutGuardService } from "src/app/guards/logout-guard/logout-guard.guard";

export const authRoutes: Routes = [
    {
        path: '',
        redirectTo:'login',
        pathMatch: 'full'
    },
    {
      path: 'login',
      component: LoginComponent,
      canActivate: [LogoutGuardService]
    },
]
