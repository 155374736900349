import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-image-popup',
  templateUrl: './image-popup.component.html',
  styleUrls: ['./image-popup.component.scss']
})
export class ImagePopupComponent {
  imgUrlLink: any = null;

  constructor(public dialogRef: MatDialogRef<ImagePopupComponent>, @Inject(MAT_DIALOG_DATA) public incomingData: any) {
    console.log(incomingData);
    this.imgUrlLink = incomingData?.data;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
