import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  marginIncreased: Boolean = false;
  currentYear: number = new Date().getFullYear();

  constructor(private router: Router){

    this.router.events.subscribe(event => {

      if (event instanceof NavigationEnd) {

        if(event.urlAfterRedirects.includes('storage-enquiry-details')  && event.urlAfterRedirects.includes('enquiry-details')){
          this.marginIncreased = true;
        }

      }

    });

  }

}
