import { Component, Renderer2, ElementRef, HostListener, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {

  enquiryDetailsUrl: any = null;
  storageEnquiryDetailsUrl: any = null;
  orderDetailsUrl: any = null;
  isSidebarClosed: Boolean = false;
  isHovered: Boolean = false;
  supDetailsUrl: any = null;
  paymentDetailsUrl: any = null;
  trackDetailsUrl: any = null;


  constructor(private router: Router, private renderer: Renderer2, private el: ElementRef){

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {

        if(event.url.includes('trip-details') && event.url.includes('enquiry-details')){
          this.enquiryDetailsUrl = event.url;
        }
        
        if(event.url.includes('trip-details') && event.url.includes('supplier-details')){
          this.supDetailsUrl = event.url;
        }

        if(event.url.includes('trip-details') && event.url.includes('payment-details')){
          this.paymentDetailsUrl = event.url;
        }

        if(event.url.includes('trip-details') && event.url.includes('tracking-details')){
          this.trackDetailsUrl = event.url;
        }

        if(event.url.includes('trip-details') && event.url.includes('order-details')){
          this.orderDetailsUrl = event.url;
        }

        if(event.url.includes('storage-enquiry-details')){
          this.storageEnquiryDetailsUrl = event.url;
        }
        
      }
    });

  }

  // @HostListener('window:resize', ['$event'])
  // onResize(event: any) {
  //   // Detect resize event and apply class based on width
  //   this.applyResponsiveClass(event.target.innerWidth);
  // }

  // applyResponsiveClass(width: number) {
  //   const targetElement = this.el.nativeElement.querySelector('.sidebar-content'); // Replace with the correct selector
  //   if (width < 992) {
  //     this.renderer.addClass(targetElement, 'close');
  //   } else {
  //     this.renderer.removeClass(targetElement, 'close');
  //   }
  // }

  // ngAfterViewInit() {
  //   // Check window size after the view has initialized
  //   this.applyResponsiveClass(window.innerWidth);
  // }

  toggleExpandTeam(id){
    const teamMenu = document.getElementById(id);
    teamMenu?.classList.toggle('expand');
  }

  toggleSidebarCollapse(): void {
    this.isSidebarClosed = !this.isSidebarClosed;
  }

  openSidebarOnHover(): void {
    if (this.isSidebarClosed) {
      this.isHovered = true; 
    }
  }

  closeSidebarOnHoverOut(): void {
    if (this.isSidebarClosed) {
      this.isHovered = false; 
    }
  }

  
  closeSidebar(){
    document.querySelector('.sidebar-content').classList.remove('mobile-open');
    document.querySelector('.overlay').classList.remove('mobile-open');
  }
  
  onNavItemClick() {
    if (window.innerWidth < 992) {
      this.closeSidebar();
    }
  }
}
