import { Injectable } from '@angular/core';
import { CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from 'src/app/authentication/services/auth-service/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate{

  constructor(public authService:AuthService,
               private router:Router) { }

  canActivate(route:any, state: RouterStateSnapshot) {
    let token = this.authService.getToken();
    if(token)
    {
      return true;
    }
    else
    {
         this.router.navigateByUrl('login',{ replaceUrl: true });
         return false;
    }
  }
}
