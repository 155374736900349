import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  dateFilterUpdated: EventEmitter<any> = new EventEmitter<any>();
  isHeaderClosed: Boolean = false;

  constructor() { }

  setDateFilter(filter: any) {
    this.dateFilterUpdated.emit(filter);
  }

  toggleHeader(){
    document.querySelector('.body-content').classList.toggle('header-hide');
    document.querySelector('header').classList.toggle('header-hide');
    document.querySelector('.custom-datatable').classList.toggle('header-hide');
    document.querySelector('.header-toggle-icon').classList.toggle('header-hide');
    this.isHeaderClosed = !this.isHeaderClosed;
  }
  
}
