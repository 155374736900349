<div class="add-popup document-popup position-relative">

    <div class="close-btn" mat-dialog-close>
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M24 44C35.0457 44 44 35.0457 44 24C44 12.9543 35.0457 4 24 4C12.9543 4 4 12.9543 4 24C4 35.0457 12.9543 44 24 44Z" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M30 18L18 30" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M18 18L30 30" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    </div>

    <div class="popup-header">
        <h2>{{incomingData?.title}}</h2>
        <img src="../../../../../../assets/images/popup-img.png" alt="">
    </div>

    <div class="popup-body">

        <div class="d-flex flex-wrap align-items-center justify-content-evenly">

            <div class="document-card position-relative" *ngFor="let document of documents">

                <ng-container *ngIf="!document?.newUrl">
                    <img *ngIf="document?.fileType != 'pdf'" [src]="document?.image_url" class="w-100 h-100" alt="">
                    <img *ngIf="document?.fileType == 'pdf'" src="../../../../../../assets/images/sample-document-pdf.png" alt="" class="w-100 h-100">
                </ng-container>

                <div class="document-overlay">
                    <a [href]="document?.image_url" target="_blank" *ngIf="!document?.newImgSrc && document?.fileType == 'pdf'">
                        <img src="../../../../../../assets/images/eye.svg" alt="" class="cursor-pointer me-1">
                    </a>

                    <img *ngIf="!document?.newImgSrc && document?.fileType != 'pdf'" (click)="openImagePreview(document?.image_url)" src="../../../../../../assets/images/eye.svg" alt="" class="cursor-pointer me-1">

                    <img (click)="documentEditImport.click()" src="../../../../../../assets/images/upload.svg" alt="" class="cursor-pointer" [ngClass]="{'ms-3': !document?.newImgSrc}">
                </div>

                <ng-container *ngIf="document?.newUrl">
                    <img *ngIf="document?.newUrl?.type != 'application/pdf'" [src]="document?.newImgSrc" alt="" class="cursor-pointer w-100 h-100 me-1">
                    <mat-icon class="position-absolute close cursor-pointer" (click)="$event.stopPropagation(); document.newUrl = null; document.newImgSrc = null">close</mat-icon>
                    <img *ngIf="document?.newUrl?.type == 'application/pdf'" src="../../../../../../assets/images/sample-document-pdf.png" class="w-100 h-100" alt="">
                </ng-container>

                <input type="file" #documentEditImport (change)="selectEditedDocument($event,document)" hidden accept="image/*,.pdf">
            </div>
            

            <div *ngIf="allowSingleDocument ? documents.length < 1 : true" class="document-card add position-relative" mat-ripple (click)="documentImport.click()">

                <ng-container *ngIf="!uploadedImageUrl">
                    <mat-icon>note_add</mat-icon>
                    <h3 class="mb-0">Add Document</h3>
                </ng-container>

                <ng-container *ngIf="uploadedImageUrl">
                    <img *ngIf="uploadedDocument?.type != 'application/pdf'" [src]="uploadedImageUrl" alt="" class="cursor-pointer w-100 h-100 me-1">
                    <mat-icon class="position-absolute close cursor-pointer" (click)="$event.stopPropagation(); uploadedDocument = null; uploadedImageUrl = null">close</mat-icon>
                    <img *ngIf="uploadedDocument?.type == 'application/pdf'" src="../../../../../../assets/images/sample-document-pdf.png" class="w-100 h-100" alt="">
                </ng-container>

            </div>
            <input type="file" #documentImport (change)="selectDocument($event)" hidden accept="image/*,.pdf">
            
        </div>

        <div class="d-flex justify-content-between">
            <button mat-button class="primary-btn mt-2 mb-4 w-100" (click)="saveDocuments()">SAVE</button>
          </div>

    </div>

</div>