import { DatePipe } from '@angular/common';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { SharedService } from '../../services/shared-service/shared.service';
import { AlertService } from '../../services/alert-service/alert.service';

@Component({
  selector: 'app-date-filter',
  templateUrl: './date-filter.component.html',
  styleUrls: ['./date-filter.component.scss']
})
export class DateFilterComponent {
  @Input() type: any;
  @Input() from: any;
  
  selectedFilter: any = {
    start: '',
    end: '',
    type: '',
    filter: null,
  };
  startDate: any = null;
  endDate: any = null;
  isApplyButtonDisabled: boolean = true;
  dateForm: FormGroup;
  @ViewChild('matMenuBtn') matMenuBtn: ElementRef;

  constructor(private datePipe: DatePipe,private sharedService: SharedService,private formBuilder: FormBuilder,
    private alertService: AlertService){

      this.dateForm = this.formBuilder.group({
        start: new FormControl<Date | null>(null),
        end: new FormControl<Date | null>(null),
      });

  }

  closeMenu() {
    const menu = document.querySelector('.date-filter-menu');
    const overlay = document.querySelector('.cdk-overlay-transparent-backdrop');
    if (menu) {
      menu.classList.remove('mdc-menu-surface--open');
      overlay.classList.remove('cdk-overlay-backdrop-showing');
      this.matMenuBtn.nativeElement.click();
    }
  }

  selectFilter(filter,range=null){

    if(filter == 1){
      this.selectedFilter.type = 'Last 1 month';
      this.selectedFilter.filter = this.generateFilterRange(30);
      this.calculateDateRange(30);
    }

    if(filter == 2){
      this.selectedFilter.type = 'Last 3 months';
      this.selectedFilter.filter = this.generateFilterRange(90);
      this.calculateDateRange(90);
    }

    if(filter == 3){
      this.selectedFilter.type = 'Last 6 months';
      this.selectedFilter.filter = this.generateFilterRange(180);
      this.calculateDateRange(180);
    }

    if(filter == 'Custom' && range) {

      if(!range.end){
        this.alertService.failureAlert('You must choose an end date !');
        this.startDate = range.start;
        this.endDate = null;
      }

      else{

        this.selectedFilter.type = 'Custom Range';
      
        const customStartDate = new Date(range.start);
        const customEndDate = new Date(range.end);
        
        const isToday = this.isSameDate(customEndDate, new Date());
  
        this.selectedFilter.start = this.datePipe.transform(customStartDate, 'dd MMM yyyy');
        this.selectedFilter.end = this.datePipe.transform(customEndDate, 'dd MMM yyyy');
  
        if (isToday) {
          this.selectedFilter['filter'] = {
            start: Math.floor(range?.start.getTime() / 1000),
            end: Math.floor(new Date().getTime() / 1000),
          };
        } else {
          this.selectedFilter['filter'] = {
            start: Math.floor(range?.start.getTime() / 1000),
            end: Math.floor(range?.end.setHours(23, 59, 0, 0) / 1000),
          };
        }

      }

    }

    if(filter == 'Custom' && !range.end){
      return false;
    }
    else{
      this.sharedService.setDateFilter({...this.selectedFilter?.filter,from:this.from});
    }

  }

  isSameDate(date1: Date, date2: Date): boolean {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  }

  generateFilterRange(days){
    const endDate = new Date();
    const startDate = new Date(endDate);
    startDate.setDate(startDate.getDate() - days); 
    startDate.setHours(0, 0, 0, 0); 

    const startUnixTimestamp = Math.floor(startDate.getTime() / 1000);
    const endUnixTimestamp = Math.floor(endDate.getTime() / 1000);

    return {
      start: startUnixTimestamp,
      end: endUnixTimestamp
    }
  }

  calculateDateRange(days) {
    const currentDate = new Date();
    const endDate = this.datePipe.transform(currentDate, 'dd MMM yyyy');
  
    const startDate = new Date(currentDate);
    startDate.setDate(currentDate.getDate() - days);
  
    this.selectedFilter.start = this.datePipe.transform(startDate, 'dd MMM yyyy');
    this.selectedFilter.end = endDate;
  }

  ngOnInit(){
    this.selectFilter(3);
  }

}
